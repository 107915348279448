export const getColor = (index: number): string => {
  const colors = [
    "bg-fuschia-40",
    "bg-pink-40",
    "bg-orange-40",
    "bg-teal-40",
    "bg-cyan-40",
    "bg-blue-40",
    "bg-indigo-40",
  ]
  return colors[index % colors.length]
}

import type { Tuple } from "@mantine/core"

const primaryColor: Record<string, Tuple<string, 10>> = {
  dark: [
    "#FAFAFA",
    "#F5F5F5",
    "#EEEEEE",
    "#E0E0E0",
    "#BDBDBD",
    "#9E9E9E",
    "#757575",
    "#616161",
    "#424242",
    "#212121",
  ],
  red: [
    "#FFDFD6",
    "#FFCFC2",
    "#FFBFAD",
    "#FFAF99",
    "#FF9F85",
    "#FF8F70",
    "#FF7F5C",
    "#FF5F33",
    "#FF4F1F",
    "#FB3700",
  ],
  green: [
    "#E3EFE9",
    "#BBD7C7",
    "#93BFA5",
    "#71A585",
    "#5D9370",
    "#4F815F",
    "#487555",
    "#406649",
    "#38563E",
    "#293B2A",
  ],
  yellow: [
    "#FFEEC2",
    "#FFE8AD",
    "#FFE299",
    "#FFDC85",
    "#FFD15C",
    "#FFCB47",
    "#FFC533",
    "#FFBF1F",
    "#FFBA0A",
    "#F3AD00",
  ],
  orange: [
    "#FFF7E0",
    "#FFEAB1",
    "#FFDC7E",
    "#FFD04A",
    "#FFC41F",
    "#FFBA00",
    "#FFAC00",
    "#FF9900",
    "#FF8700",
    "#FF6600",
  ],
}

export const mantineColors = {
  ...primaryColor,
}

export const sendBirdColorSet = {
  "--sendbird-light-primary-500": "#00487c",
  "--sendbird-light-primary-400": "#293B2A",
  "--sendbird-light-primary-300": "#293B2A",
  "--sendbird-light-primary-200": "#293B2A",
  "--sendbird-light-primary-100": "#293B2A",
  "--sendbird-light-secondary-500": "#9E9E9E",
  "--sendbird-light-secondary-400": "#9E9E9E",
  "--sendbird-light-secondary-300": "#9E9E9E",
  "--sendbird-light-secondary-200": "#9E9E9E",
  "--sendbird-light-secondary-100": "#9E9E9E",
  "--sendbird-light-background-50": "#F4F1EF",
  "--sendbird-light-background-100": "#F5F5F5",
  "--sendbird-light-background-200": "#EEEEEE",
  "--sendbird-light-background-300": "#E0E0E0",
  "--sendbird-light-background-400": "#BDBDBD",
  "--sendbird-light-background-500": "#9E9E9E",
  "--sendbird-light-background-600": "#757575",
  "--sendbird-light-background-700": "#616161",
}
