import { Context, ILogLevel, Logtail } from "@logtail/browser"
import { createContext, useContext } from "react"

interface LoggingContext {
  log: (message: string, level?: ILogLevel, context?: Context) => void
}

const LoggingContext = createContext<LoggingContext | null>(null)

interface LoggingProviderProps {
  children?: React.ReactNode
}

export const LoggingProvider = ({ children }: LoggingProviderProps) => {
  const isDevEnvironment =
    import.meta.env.NODE_ENV === "development" ||
    import.meta.env.NODE_ENV === "test"

  const logtail = new Logtail(
    import.meta.env.VITE_BETTERSTACK_SOURCE_TOKEN || "",
    {
      sendLogsToConsoleOutput: isDevEnvironment,
      sendLogsToBetterStack: !isDevEnvironment,
    }
  )

  const log = (message: string, level?: ILogLevel, context?: Context) => {
    logtail.log(message, level, context)
    logtail.flush()
  }

  return (
    <LoggingContext.Provider value={{ log }}>
      {children}
    </LoggingContext.Provider>
  )
}

export const useLogger = () => {
  const context = useContext(LoggingContext)
  if (!context) {
    throw new Error("useLogger must be used within a LoggingContext")
  }
  return context
}
