import "@sendbird/uikit-react/dist/index.css"
import * as Sentry from "@sentry/react"
import { QueryClientProvider } from "@tanstack/react-query"
import { MedusaProvider } from "medusa-react"
import type { PropsWithChildren } from "react"
import { createRoot } from "react-dom/client"
import App from "./App"
import "./assets/styles/global.css"
import { LayeredModalProvider } from "./components/molecules/modal/layered-modal"
import { SteppedProvider } from "./components/molecules/modal/stepped-modal"
import { AccountProvider } from "./context/account"
import AnalyticsProvider from "./context/analytics"
import { CacheProvider } from "./context/cache"
import { FeatureFlagProvider } from "./context/feature-flag"
import { InterfaceProvider } from "./context/interface"
import { LoggingProvider } from "./context/logging"
import { PusherProvider } from "./context/pusher"
import { medusaUrl } from "./services/config"
import queryClient from "./services/queryClient"
import tanstackQueryClient from "./services/tanstackQueryClient"

Sentry.init({
  dsn: "https://d61d2a9f37f96da9d18a1825c2f54f0e@o4504221075570688.ingest.sentry.io/4506440268513280",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/v3-api\.foraged\.com/],
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

const Page = ({ children }: PropsWithChildren) => {
  return (
    <MedusaProvider
      baseUrl={medusaUrl}
      queryClientProviderProps={{
        client: queryClient,
      }}
    >
      <QueryClientProvider client={tanstackQueryClient}>
        <CacheProvider>
          <AnalyticsProvider>
            <LoggingProvider>
              <PusherProvider>
                <AccountProvider>
                  <FeatureFlagProvider>
                    <InterfaceProvider>
                      <SteppedProvider>
                        <LayeredModalProvider>{children}</LayeredModalProvider>
                      </SteppedProvider>
                    </InterfaceProvider>
                  </FeatureFlagProvider>
                </AccountProvider>
              </PusherProvider>
            </LoggingProvider>
          </AnalyticsProvider>
        </CacheProvider>
      </QueryClientProvider>
    </MedusaProvider>
  )
}

const root = createRoot(document.getElementById("root")!)
root.render(
  <Page>
    <App />
  </Page>
)
