import { AnalyticsBrowser } from "@segment/analytics-next"
import { useAdminGetSession, useAdminStore } from "medusa-react"
import React, { createContext, useContext, useEffect } from "react"
import { User } from "~/types/shared"

export enum AnalyticsEvent {
  SENT_THANK_YOU_NOTE = "Sent Thank You Note",
  SELECTED_SHOPIFY = "Selected Shopify",
  SHOPIFY_SYNCING_COMPLETE = "Shopify Syncing Complete",
  BECAME_VENDOR = "Became Vendor",
  VIEWED_ONBOARDING_STEP = "Viewed Onboarding Step",
  CREATED_VENDOR_STORE = "Created Vendor Store",
  VENDOR_SUBSCRIBED = "Vendor Subscribed",
  VENDOR_ONBOARDING_AUTH0_REDIRECT = "Viewed Vendor Onboarding Login/Sign Up Page",
}

type AnalyticsContextType = {
  analytics: AnalyticsBrowser
}

const AnalyticsContext = createContext<AnalyticsContextType | null>(null)

interface Props {
  children?: React.ReactNode
}
const AnalyticsProvider = ({ children }: Props) => {
  const session = useAdminGetSession()
  const user: Omit<User, "password_hash"> | undefined = session.user

  const { store } = useAdminStore()

  const analytics = AnalyticsBrowser.load({
    writeKey: import.meta.env.VITE_SEGMENT_WRITE_KEY,
  })

  useEffect(() => {
    if (!user?.id) {
      return
    }
    analytics.identify(user.id, {
      email: user?.email ?? null,
      vendorId: user?.vendor_id ?? null,
      store: store?.name ?? null,
    })
  }, [user])

  return (
    <AnalyticsContext.Provider value={{ analytics }}>
      {children}
    </AnalyticsContext.Provider>
  )
}

export const useAnalytics = () => {
  const context = useContext(AnalyticsContext)

  if (!context) {
    throw new Error("useAnalytics must be used within a AnalyticsProvider")
  }

  return context
}

export default AnalyticsProvider
