import Pusher from "pusher-js"
import { createContext, useContext } from "react"

interface PusherContext {
  pusher: Pusher
}

const PusherContext = createContext<PusherContext | null>(null)

interface LoggingProviderProps {
  children?: React.ReactNode
}

export const PusherProvider = ({ children }: LoggingProviderProps) => {
  const pusher = new Pusher(import.meta.env.VITE_PUSHER_KEY, {
    cluster: import.meta.env.VITE_PUSHER_CLUSTER,
  })

  return (
    <PusherContext.Provider value={{ pusher }}>
      {children}
    </PusherContext.Provider>
  )
}

export const usePusher = () => {
  const context = useContext(PusherContext)
  if (!context) {
    throw new Error("usePusher must be used within a PusherContext")
  }
  return context
}
