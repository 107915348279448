import { adminUserKeys } from "medusa-react"
import React, { useContext, useEffect, useReducer, useState } from "react"
import {
  VendorCapabilities,
  VendorSaasSubscription,
  VendorSaasTier,
} from "~/types/shared"
import Medusa from "../services/api"
import { medusaUrl } from "../services/config"
import queryClient from "../services/queryClient"
import { useLogger } from "./logging"
import { usePusher } from "./pusher"

export enum PlanType {
  ALL_ACCESS = "ALL ACCESS",
  LEGACY = "LEGACY",
  MARKETPLACE = "MARKETPLACE",
  LOCAL = "LOCAL",
  SHOPIFY = "SHOPIFY",
}

interface AccountContextType {
  isLoggedIn: boolean
  id: string
  name: string
  first_name: string
  last_name: string
  email: string
  role: string
  vendorId?: string
  googleMerchantAccountId: string
  googleMerchantPhoneVerified: boolean
  googleMerchantAdsId: string
  metadata: {
    auth0: {
      id: string
      picture: string
      provider: string
    }
    authProvider: string
  }
  loadingAccount: boolean
  vendorCapabilities: VendorCapabilities[]
  isLegacyVendor?: boolean
  stripeSubscriptionId: string
  planType?: PlanType | null
  hasUnfulfilledOrders: boolean
  vendorSaasTier?: VendorSaasTier
  vendorSaasSubscription?: VendorSaasSubscription
}

export const defaultAccountContext: AccountContextType = {
  isLoggedIn: false,
  id: "",
  name: "",
  first_name: "",
  last_name: "",
  email: "",
  role: "member", // vendor
  vendorId: undefined,
  googleMerchantAccountId: "",
  googleMerchantPhoneVerified: false,
  googleMerchantAdsId: "",
  metadata: {
    auth0: {
      id: "",
      picture: "",
      provider: "",
    },
    authProvider: "",
  },
  loadingAccount: true,
  vendorCapabilities: [],
  stripeSubscriptionId: "",
  planType: null,
  hasUnfulfilledOrders: false,
}

const extractPayloadData = (action) => {
  return {
    id: action.payload.id,
    email: action.payload.email,
    first_name: action.payload?.first_name,
    last_name: action.payload?.last_name,
    role: action.payload?.role,
    vendorId: action.payload?.vendor_id,
    metadata: action.payload?.metadata,
    googleMerchantAccountId: action.payload?.vendor?.googleMerchantAccountId,
    googleMerchantPhoneVerified:
      action.payload?.vendor?.googleMerchantPhoneVerified,
    googleMerchantAdsId: action.payload?.vendor?.googleMerchantAdsId,
    vendorCapabilities: action.payload?.vendorCapabilities,
    isLegacyVendor: action.payload?.isLegacyVendor,
    stripeSubscriptionId: action.payload?.stripeSubscriptionId,
    planType: action.payload?.planType,
    hasUnfulfilledOrders: action.payload?.hasUnfulfilledOrders,
    vendorSaasTier: action.payload?.vendorSaasTier,
    vendorSaasSubscription: action.payload?.vendorSaasSubscription,
  }
}

export const AccountContext = React.createContext(defaultAccountContext)

export const AccountProvider = ({ children }) => {
  const { pusher } = usePusher()
  const { log } = useLogger()

  const reducer = (state, action) => {
    switch (action.type) {
      case "userAuthenticated":
        log("[AccountContext] Account context hydrated", "info", { state })
        return {
          ...state,
          isLoggedIn: true,
          ...extractPayloadData(action),
        }
      case "updateUser":
        return {
          ...state,
          ...action.payload,
        }
      case "userLoggedOut":
        return defaultAccountContext
      case "userLoggedIn":
        return {
          ...state,
          ...extractPayloadData(action),
        }
      default:
        return state
    }
  }

  const [state, dispatch] = useReducer(reducer, defaultAccountContext)
  const [loadingAccount, setLoadingAccountState] = useState(true)

  useEffect(() => {
    if (loadingAccount || !state.stripeSubscriptionId) {
      return
    }

    const channel = pusher.subscribe(state.stripeSubscriptionId)

    channel.bind("updated", (data) => {
      dispatch({
        type: "updateUser",
        payload: {
          stripeSubscriptionId: data.stripeSubscriptionId,
          vendorCapabilities: data.vendorCapabilities,
        },
      })
    })

    channel.bind("canceled", () => {
      dispatch({
        type: "updateUser",
        payload: {
          stripeSubscriptionId: null,
          vendorCapabilities: [],
        },
      })
    })

    return () => {
      pusher.unsubscribe(state.stripeSubscriptionId)
    }
  }, [state])

  return (
    <AccountContext.Provider
      value={{
        ...state,
        session: async () => {
          setLoadingAccountState(true)
          const { data } = await Medusa.auth.session()
          log("User authenticated by Auth0", "info", { data })
          dispatch({ type: "userAuthenticated", payload: data.user })
          setLoadingAccountState(false)
          return data
        },

        handleUpdateUser: (id, user) => {
          setLoadingAccountState(true)

          return Medusa.users.update(id, user).then(({ data }) => {
            queryClient.invalidateQueries(adminUserKeys.all)
            dispatch({ type: "updateUser", payload: data.user })
            setLoadingAccountState(false)
          })
        },

        handleLogout: (details) => {
          return Medusa.auth.deauthenticate().then(() => {
            dispatch({ type: "userLoggedOut" })

            window.location.href = `${medusaUrl}/admin/auth/logout?returnTo=${window.location.origin}`

            return null
          })
        },

        handleLogin: (details) => {
          setLoadingAccountState(true)

          return Medusa.auth.authenticate(details).then(({ data }) => {
            dispatch({ type: "userLoggedIn", payload: data.user })
            setLoadingAccountState(false)
            return data
          })
        },
        loadingAccount,
      }}
    >
      {children}
    </AccountContext.Provider>
  )
}

export const useAccountContext = () => {
  return useContext(AccountContext)
}
