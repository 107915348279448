import { GrowthBook } from "@growthbook/growthbook-react"
import { useEffect, useState } from "react"
import { v4 as uuidv4 } from "uuid"

export const useGrowthBook = () => {
  const [growthbook, setGrowthbook] = useState<GrowthBook>()

  useEffect(() => {
    if (typeof window !== "undefined") {
      let uniqueDeviceID = localStorage.getItem("uniqueDeviceID")
      if (!uniqueDeviceID) {
        uniqueDeviceID = uuidv4()
        localStorage.setItem("uniqueDeviceID", uniqueDeviceID)
      }

      const gb = new GrowthBook({
        apiHost: "https://cdn.growthbook.io",
        clientKey: import.meta.env.VITE_GROWTHBOOK_KEY || "",
        enableDevMode: true,
        subscribeToChanges: true,
        attributes: {
          deviceId: uniqueDeviceID,
        },
      })

      setGrowthbook(gb)
    }
  }, [])

  return growthbook
}
