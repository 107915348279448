import { GrowthBookProvider } from "@growthbook/growthbook-react"
import { get } from "@vercel/edge-config"
import { lazy, Suspense, useEffect } from "react"
import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route,
  RouterProvider,
  useLocation,
} from "react-router-dom"
import { useAccount } from "~/hooks/use-account"
import Spinner from "./components/atoms/spinner"
import { useAnalytics } from "./context/analytics"
import MantineThemeProvider from "./context/mantine-theme"
import { useGrowthBook } from "./hooks/use-growthbook"

const NotFound = lazy(() => import("./pages/404"))
const Dashboard = lazy(() => import("./pages/a"))
const IndexPage = lazy(() => import("./pages/index"))
const InvitePage = lazy(() => import("./pages/invite"))
const LoginPage = lazy(() => import("./pages/login"))
const SignupPage = lazy(() => import("./pages/signup"))
const ResetPasswordPage = lazy(() => import("./pages/reset-password"))
const VendorPage = lazy(() => import("./pages/vendor"))
const NoVendorPage = lazy(() => import("./pages/no-vendor"))

const VITE_STOREFRONT_URL = import.meta.env.VITE_STOREFRONT_URL

const Analytics = () => {
  const location = useLocation()
  const { analytics } = useAnalytics()

  useEffect(() => {
    analytics.page()
  }, [location])

  return <Outlet />
}

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<Analytics />}>
      <Route path="/" element={<IndexPage />} />
      <Route path="a/*" element={<Dashboard />} />
      <Route path="vendor">
        <Route path="no-vendor" element={<NoVendorPage />} />
        <Route path="onboarding" element={<VendorPage />} />
      </Route>
      <Route path="invite" element={<InvitePage />} />
      <Route path="login" element={<LoginPage />} />
      <Route path="signup" element={<SignupPage />} />
      <Route path="reset-password" element={<ResetPasswordPage />} />
      <Route path="*" element={<NotFound />} />
    </Route>
  )
)

const Loading = () => (
  <div className="flex h-screen w-full items-center justify-center bg-grey-25 text-grey-90">
    <Spinner variant="secondary" />
  </div>
)

const App = () => {
  const account = useAccount()

  const growthbook = useGrowthBook()

  useEffect(() => {
    const fetchMaintenanceMode = async () => {
      const maintenance = (await get<boolean>("maintenance")) ?? false

      if (maintenance) {
        window.location.href = VITE_STOREFRONT_URL
      }
    }

    fetchMaintenanceMode().catch(console.error)
  }, [])

  useEffect(() => {
    if (growthbook) {
      growthbook.loadFeatures()
    }
  }, [growthbook])

  useEffect(() => {
    if (growthbook && account.isLoggedIn) {
      growthbook.setAttributes({
        id: account.id,
        email: account.email,
        role: account.role,
        vendorId: account.vendorId,
        vendorCapabilities: account.vendorCapabilities,
        planType: account.planType,
        stripeSubscriptionId: account.stripeSubscriptionId,
        isLegacyVendor: account.isLegacyVendor,
      })
    }
  }, [growthbook, account])

  return (
    <Suspense fallback={<Loading />}>
      <MantineThemeProvider>
        <GrowthBookProvider growthbook={growthbook}>
          <RouterProvider router={router} />
        </GrowthBookProvider>
      </MantineThemeProvider>
    </Suspense>
  )
}

export default App
