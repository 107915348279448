import type {
  ButtonStylesParams,
  DefaultMantineColor,
  Tuple,
} from "@mantine/core"
import type { ReactNode } from "react"

import { MantineProvider } from "@mantine/core"
import { mantineColors } from "../utils/color"

// eslint-disable-next-line no-undef
type ExtendedCustomColors = DefaultMantineColor | PrimaryColors

declare module "@mantine/core" {
  export interface MantineThemeColorsOverride {
    colors: Record<ExtendedCustomColors, Tuple<string, 10>>
  }
}

type ThemeProviderProps = {
  children: ReactNode
}

const MantineThemeProvider = ({ children }: ThemeProviderProps) => {
  return (
    <MantineProvider
      theme={{
        colorScheme: "light",
        colors: mantineColors,
        primaryColor: "green",
        cursorType: "pointer",
        fontFamily: "Caslon Ionic",
        headings: {
          fontFamily: "Champion",
          sizes: {
            h1: {
              fontSize: "93px",
              fontWeight: "lighter",
              lineHeight: "106px",
            },
            h2: {
              fontSize: "60px",
              fontWeight: "normal",
              lineHeight: "69px",
            },
            h3: {
              fontSize: "60px",
              fontWeight: "lighter",
              lineHeight: "69px",
            },
            h4: {
              fontSize: "48px",
              fontWeight: "lighter",
              lineHeight: "60px",
            },
            h5: {
              fontSize: "31px",
              fontWeight: "lighter",
              lineHeight: "48px",
            },
            h6: {
              fontSize: "22px",
              fontWeight: "lighter",
              lineHeight: "28px",
            },
          },
        },
        spacing: {
          xs: "0.5rem",
          sm: "1rem",
          md: "1.5em",
          lg: "2rem",
          xl: "2.5rem",
        },
        components: {
          Container: {
            styles: {
              root: {
                boxSizing: "content-box",
              },
            },
          },
          Title: {
            styles: {
              root: {
                textTransform: "uppercase",
              },
            },
          },
          Button: {
            defaultProps: {
              color: "green.9",
            },
            styles(theme, params: ButtonStylesParams, context) {
              return {
                root: {
                  backgroundColor:
                    context.variant === "filled"
                      ? `${theme.colors.green[7]} !important`
                      : undefined,
                  "&:disabled": {
                    backgroundColor:
                      context.variant === "filled"
                        ? `${theme.colors.gray[2]} !important`
                        : undefined,
                  },
                  "&:hover": {
                    backgroundColor:
                      context.variant === "filled"
                        ? `${theme.colors.green[6]} !important`
                        : undefined,
                  },
                },
                label: {
                  fontWeight: 400,
                },
              }
            },
          },
          Radio: {
            styles: {
              label: {
                paddingLeft: 12,
              },
            },
          },
          Checkbox: {
            styles: {
              label: {
                paddingLeft: 12,
              },
            },
          },
          Switch: {
            defaultProps: {
              color: "green.9",
            },
            styles: {
              body: {
                display: "flex",
              },
              track: {
                borderWidth: 0,
                backgroundColor: "rgba(41, 59, 42, 0.1);",
                width: 38,
              },
              thumb: {
                borderWidth: 0,
              },
              label: {
                paddingLeft: 12,
              },
            },
          },
          ActionIcon: {
            defaultProps: {
              size: "xl",
            },
            styles: {
              root: {
                height: "36px",
                width: "36px",
                minHeight: "36px",
                minWidth: "36px",
              },
            },
          },
          Accordion: {
            styles: {
              control: {
                "&:hover": {
                  backgroundColor: "transparent",
                },
              },
            },
          },
          Modal: {
            styles: (theme) => ({
              title: {
                fontFamily: "Champion",
                fontSize: "22px",
                lineHeight: "28px",
                fontWeight: 325,
                textTransform: "uppercase",
              },
              header: {
                backgroundColor: "#F4F1EF",
              },
              content: {
                backgroundColor: "#F4F1EF",
              },
              close: {
                "&:hover": {
                  backgroundColor: "transparent",
                },
                "& svg": {
                  height: "20px",
                  width: "20px",
                  strokeWidth: 1.5,
                },
              },
            }),
          },
          Skeleton: {
            styles: (theme) => ({
              root: {
                "&::after": {
                  background: `${theme.colors.dark[4]} !important`,
                },
              },
            }),
          },
          Select: {
            styles: (theme) => ({
              disabled: {
                color: `${theme.colors.dark[9]} !important`,
              },
            }),
          },
          Indicator: {
            styles: () => ({
              indicator: {
                fontSize: 10,
              },
            }),
          },
        },
        black: "#25262B",
        // custom properties
        other: {
          body: {
            backgroundColor: "#F4F1EF",
          },
        },
      }}
      withGlobalStyles
      withNormalizeCSS
    >
      {children}
    </MantineProvider>
  )
}

export default MantineThemeProvider
