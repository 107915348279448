import axios, { AxiosPromise, AxiosRequestConfig, Method } from "axios"
import { medusaUrl } from "./config"

const client = axios.create({ baseURL: medusaUrl })

export default async function medusaRequest<T>(
  method: Method,
  path = "",
  payload = {}
) {
  const options: AxiosRequestConfig = {
    method,
    withCredentials: true,
    url: path,
    data: payload,
  }
  return client(options) as AxiosPromise<T>
}
